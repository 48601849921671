<template>
  <v-dialog v-model="dialogPrint" persistent max-width="1500px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="px-2 py-0">
              <span class="display-2 font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.print") }}
              </span>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-select
                :items="printTypes"
                label="Select Print Type"
                v-model="printType"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field
                label="Numbers of Product"
                v-model="searchForm.limit"
                clearable
                type="number"
                @change="handleProductNum"
              />
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="this.datas"
            :page="data_table_options.page"
            :server-items-length="data_table_options.totalItems"
            :options="data_table_options"
            :footer-props="data_table_options.footerProps"
            mobile-breakpoint="960"
            hide-default-footer
            class="elevation-1 printTable"
            id="printMe"
          >
            <template v-slot:[`item.image`]="{ item }">
              <img
                :src="checkImage(item)"
                width="50px"
                style="
                  width: 65px;
                  height: 70px;
                  margin: 10px;
                  border-radius: 50px;
                "
              />
            </template>

            <template v-slot:[`item.note`]="{ item }">
              {{ checkStrLength(item.note) }}
              <span class="blue--text" v-if="item.note.length > 30"
                >.....more</span
              >
            </template>

            <template v-slot:[`item.address`]="{ item }">
              {{ checkStrLength(item.address) }}
              <span class="blue--text" v-if="item.address.length > 30"
                >.....more
              </span>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />

        <v-btn color="red darken-2" outlined @click="cancelForm">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="blue darken-2" @click="ConfirmPrint">
          {{ $vuetify.lang.t("$vuetify.confirm") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

//TODO Print in A4 paper table no border

<script>
import serverConfig from "@/utils/serverConfig";
import { json2excel } from "js2excel";
import { warehouseGoods } from "@/api/purchase";

const newSearch = () => {
  return {
    skip: 0,
    limit: 10,
    warehouse_id: "",
    goods_type: 1,
    goods_id: "",
  };
};

const newTableOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

export default {
  name: "printTable",
  props: {
    dialogPrint: Boolean,
  },
  data() {
    return {
      searchForm: newSearch(),
      datas: [],
      data_table_options: newTableOption(),
      printType: "Excel",
      printTypes: [
        {
          text: "A4",
          value: "A4",
        },
        {
          text: "Excel",
          value: "Excel",
        },
      ],
      imgUrl: serverConfig.img_url,
      headers: [
        {
          text: "No.",
          value: "itemNo",
          sortable: false,
          width: "40px",
        },
        {
          text: "Product No.",
          value: "goods_info.other_code",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "Product Img",
          value: "image",
          align: "center",
          sortable: false,
          width: "100px",
        },
        {
          text: "Name",
          value: "goods_info.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Stock Qty",
          value: "inventory",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ConfirmPrint() {
      if (this.printType == "Excel") {
        let data = this.setPrintData();
        try {
          json2excel({
            data,
            name: "WarehouseProducts-data",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("export error");
        }
      } else if (this.printType == "A4") {
        this.$htmlToPaper("printMe");
      }
    },
    setPrintData() {
      var printData = [];

      for (var i = 0; i < this.datas.length; i++) {
        var printObj = {};
        this.$set(printObj, "No .", this.datas[i].itemNo);
        // this.$set(printObj, "Product Image", this.checkImage(this.datas[i]));
        this.$set(printObj, "Product No.", this.datas[i].goods_info.other_code);
        this.$set(printObj, "Name", this.datas[i].goods_info.name);
        this.$set(printObj, "Stock Qty", this.datas[i].inventory);

        printData.push(printObj);
      }
      return printData;
    },
    cancelForm() {
      this.$emit("update:dialogPrint", false);
    },
    checkImage(item) {
      return typeof item.image === "string"
        ? item.image == ""
          ? this.blankProduct
          : this.imgUrl + item.image
        : this.blankProduct;
    },
    handleProductNum() {
      this.getData();
    },
    getData() {
      warehouseGoods(this.searchForm)
        .then((res) => {
          if (res.items != null) {
            this.datas = [...res.items];

            this.data_table_options.totalItems =
              res.total <= 10 ? 1 : Math.round(res.total / 10 + 1);

            // this.datas.forEach((p) => {
            //   p.image = p.goods_info.image;
            //   console.log("image: ", p.image)
            //   if (p.image.includes(",")) {
            //     p.image = p.image.split(",");
            //   } else {
            //     const temp = [];

            //     temp.push(p.image);

            //     p.image = temp;
            //   }
            // });

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
              this.datas[i].image = this.datas[i].goods_info.image;
            }
          } else {
            this.datas = [];
            this.data_table_options = newTableOption();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
  mounted() {
    this.searchForm.warehouse_id = this.$route.params.id;
    this.getData();
  },
};
</script>
<style>
.printTable th + th {
  border-left: 1px solid #dddddd;
}
.printTable td + td {
  border-left: 1px solid #dddddd;
}
</style>